<template>
  <div></div>
</template>

<script>
import axios from "axios";
import store from "../store/stores";

export default {
  name: "AuthSSO",

  data() {
    return {
      token: null
    };
  },

  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.token = urlParams.get("token");
    await this.setSSOToken();
  },

  methods: {
    async setSSOToken() {
      await axios
        .post(process.env.VUE_APP_AUTH_URL + "/oauth/token", {
          grant_type: "social_grant",
          client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
          provider_token: this.token,
          scope: ""
        })
        .then(async data => {
          store.commit("userStore/setTokens", data.data);
          const lastPage = JSON.parse(window.sessionStorage.getItem("lastPage"));
          const redirectData = lastPage
            ? { name: lastPage.name, params: lastPage.params, query: lastPage.query }
            : { name: "Home" };
          await this.$router.replace(redirectData);
        })
        .catch(e => {
          throw new Error(e);
        });
    }
  }
};
</script>
